import { app } from "@/main";
import store from "@/store";
import { TimelineChartData } from "@/models/reporting-analytics/timeline-data";
import {
  HistoricalRecogData,
  HistoricalRecogDataRow
} from "@/models/reporting-analytics/historical-recognition-data";
import { CategoryChartData } from "@/models/reporting-analytics/category-chart-data";
import {
  InvoiceAutoAddData,
  InvoiceAutoAddDataRow
} from "@/models/reporting-analytics/invoice-auto-add-data";
import {
  InvoiceListData,
  InvoiceListFieldsData
} from "@/models/reporting-analytics/invoice-list-data";
import moment from "moment";
import { utils } from "@/utils/okta-utils";

class ReportingAnalyticsService {
  // PRIVATE METHODS WITH API ENDPOINTS

  private getRecognitionApiDataAsync = async (requestParams: {
    chartType: string;
    startDate: string;
    stopDate: string;
    filterType: string;
    filterVal: string;
    includeValidationServices: boolean;
  }): Promise<any> => {
    let requestUrl = "";

    switch (requestParams.chartType) {
      case "recognitionHistorical":
        requestUrl +=
          "api/analytics/historicalrecognitionrates?startDate=" +
          requestParams.startDate +
          "&stopDate=" +
          requestParams.stopDate +
          "&vs=" +
          requestParams.includeValidationServices;
        break;
      case "recognitionByHeaderField":
        requestUrl +=
          "api/analytics/ratesbyheaderfield?startDate=" +
          requestParams.startDate +
          "&stopDate=" +
          requestParams.stopDate +
          "&vs=" +
          requestParams.includeValidationServices;

        if (
          requestParams.filterType === "supplierId" &&
          requestParams.filterVal !== ""
        ) {
          // recog by header field for a selected supplier
          requestUrl += "&supplierId=" + requestParams.filterVal;
        }
        break;
      case "recognitionBySupplier":
        if (
          requestParams.filterType === "fieldName" &&
          requestParams.filterVal !== ""
        ) {
          // recog by supplier for a single header field
          requestUrl +=
            "api/analytics/ratesbysupplier?startDate=" +
            requestParams.startDate +
            "&stopDate=" +
            requestParams.stopDate +
            "&field=" +
            requestParams.filterVal;
        } else {
          // overall recognition rate by supplier
          requestUrl +=
            "api/analytics/ratesbysupplier?startDate=" +
            requestParams.startDate +
            "&stopDate=" +
            requestParams.stopDate +
            "&vs=" +
            requestParams.includeValidationServices;
        }
        break;
      default:
        break;
    }
    return app.$ravenapi.get(requestUrl);
  };

  private getInvoiceApiDataAsync = async (requestParams: {
    chartType: string;
    startDate: string;
    stopDate: string;
  }): Promise<any> => {
    let requestUrl = "api/analytics/";
    let endpoint = "";

    const queryParams =
      "?startDate=" +
      requestParams.startDate +
      "&stopDate=" +
      requestParams.stopDate;

    switch (requestParams.chartType) {
      case "invoiceVolumeBySupplier":
        endpoint = "volumebysupplier";
        break;
      case "invoicesEnteredByProcessor":
        endpoint = "volumebyapprocessor";
        break;
      case "invoiceVolumeByAssignment":
        endpoint = "invoicesbyassignment";
        break;
      case "historicalInvoiceVolume":
        endpoint = "historicalinvoicevolume";
        break;
      case "invoiceVolumeAutoAdded":
        endpoint = "invoicesautoadded";
        break;
      case "invoiceCancelledBySupplier":
        endpoint = "cancelledbysupplier";
        break;
      case "touchlessProcessing":
        endpoint = "touchlessprocessing";
        break;
      default:
        break;
    }

    requestUrl += endpoint + queryParams;

    return app.$ravenapi.get(requestUrl);
  };

  private getInvoiceListApiDataAsync = async (requestParams: {
    startDate: string;
    stopDate: string;
    dateType: string;
    supplierId?: string;
  }): Promise<any> => {
    let requestUrl =
      "api/analytics/invoicelist?startDate=" +
      requestParams.startDate +
      "&stopDate=" +
      requestParams.stopDate +
      "&dateType=" +
      requestParams.dateType;

    // obtains invoice list for single supplier
    if (requestParams.supplierId) {
      requestUrl += "&supplierId=" + requestParams.supplierId;
    }

    const apiResponse = await app.$ravenapi.get(requestUrl);
    return apiResponse.data.data;
  };

  // PUBLIC METHODS TO LOAD RECOG DATA FROM API

  public loadRecogHistoricalAsync = async (
    startDate: string,
    stopDate: string,
    includeValidationServices: boolean
  ): Promise<{ [key: string]: TimelineChartData[] }> => {
    const fullData: HistoricalRecogData = await this.getRecognitionApiDataAsync(
      {
        chartType: "recognitionHistorical",
        startDate: startDate,
        stopDate: stopDate,
        filterType: "",
        filterVal: "",
        includeValidationServices: includeValidationServices
      }
    );

    const data = fullData["rows"];

    const results: { [key: string]: TimelineChartData[] } = {
      recogRate: [],
      sampleSize: []
    };

    // separate daily recog rates and sample sizes
    data.forEach((row: HistoricalRecogDataRow) => {
      results.recogRate.push({
        x: row.fieldText,
        y:
          row.fieldCount > 0
            ? parseFloat(
                ((row.recognitionCount / row.fieldCount) * 100).toFixed(1)
              )
            : 0
      });
      results.sampleSize.push({
        x: row.fieldText,
        y: row.fieldCount
      });
    });

    // calculate overall average recog rate
    if (data.length > 0) {
      const overallRecogRate = (
        (data.reduce(
          (accum: number, current: any) => accum + current.recognitionCount,
          0
        ) /
          data.reduce(
            (accum: number, current: any) => accum + current.fieldCount,
            0
          )) *
        100
      )
        .toFixed(1)
        .concat("%");

      // store overall average recog rate
      store.commit(
        "RecognitionRateAnalyticsStore/setAverageOverallRecogRate",
        overallRecogRate
      );
      // store num of fields used to calculate recog rate
      store.commit(
        "RecognitionRateAnalyticsStore/setFieldsAnalyzed",
        parseFloat(
          data
            .reduce(
              (accum: number, current: any) => accum + current.fieldCount,
              0
            )
            .toFixed()
        ).toLocaleString()
      );
    } else {
      // store default values for no data
      store.commit(
        "RecognitionRateAnalyticsStore/setAverageOverallRecogRate",
        "N/A"
      );
      store.commit("RecognitionRateAnalyticsStore/setFieldsAnalyzed", "0");
    }

    return results;
  };

  private processRecogByFieldSeries = (
    data: HistoricalRecogDataRow[]
  ): { [key: string]: CategoryChartData[] } => {
    const results: { [key: string]: CategoryChartData[] } = {
      recogRate: [],
      sampleSize: []
    };

    // separate recog rates and sample sizes
    data.forEach((obj: HistoricalRecogDataRow) => {
      results.recogRate.push({
        x: obj.fieldText,
        y:
          obj.fieldCount > 0
            ? Math.floor((obj.recognitionCount / obj.fieldCount) * 100)
            : 0
      });
      results.sampleSize.push({
        x: obj.fieldText,
        y: obj.fieldCount
      });
    });

    return results;
  };

  public loadRecogbyFieldAsync = async (
    startDate: string,
    stopDate: string,
    includeValidationServices: boolean
  ): Promise<{ [key: string]: CategoryChartData[] }> => {
    const fullData: HistoricalRecogData = await this.getRecognitionApiDataAsync(
      {
        chartType: "recognitionByHeaderField",
        startDate: startDate,
        stopDate: stopDate,
        filterType: "",
        filterVal: "",
        includeValidationServices: includeValidationServices
      }
    );

    const data = fullData["rows"];
    return this.processRecogByFieldSeries(data);
  };

  public loadRecogByFieldForSupplierAsync = async (
    startDate: string,
    stopDate: string,
    includeValidationServices: boolean,
    supplierId: string
  ): Promise<{ [key: string]: CategoryChartData[] }> => {
    const fullData: HistoricalRecogData = await this.getRecognitionApiDataAsync(
      {
        chartType: "recognitionByHeaderField",
        startDate: startDate,
        stopDate: stopDate,
        filterType: "supplierId",
        filterVal: supplierId,
        includeValidationServices: includeValidationServices
      }
    );

    const data = fullData["rows"];
    return this.processRecogByFieldSeries(data);
  };

  public loadRecogBySupplierAsync = async (
    startDate: string,
    stopDate: string,
    includeValidationServices: boolean
  ): Promise<{ [key: string]: CategoryChartData[] }> => {
    const fullData: HistoricalRecogData = await this.getRecognitionApiDataAsync(
      {
        chartType: "recognitionBySupplier",
        startDate: startDate,
        stopDate: stopDate,
        filterType: "",
        filterVal: "",
        includeValidationServices: includeValidationServices
      }
    );

    const data = fullData["rows"];

    const results: { [key: string]: CategoryChartData[] } = {
      recognition: [],
      volume: []
    };

    // store number of suppliers
    store.commit(
      "RecognitionRateAnalyticsStore/setNumberOfSuppliers",
      data.length
    );

    // calculate recog rates for each supplier
    const totalFieldsAnalyzed: number = data.reduce(
      (accum: number, current: any) => accum + current.fieldCount,
      0
    );

    data.forEach((obj: HistoricalRecogDataRow) => {
      const percentTotalInvoiceVolume: number =
        totalFieldsAnalyzed > 0
          ? parseFloat(
              ((obj.fieldCount / totalFieldsAnalyzed) * 100).toFixed(2)
            )
          : 0;

      results.recognition.push({
        x: `${obj.fieldText} /${obj.fieldID}`,
        y:
          obj.fieldCount > 0
            ? Math.floor((obj.recognitionCount / obj.fieldCount) * 100)
            : 0
      });
      results.volume.push({
        x: `${obj.fieldText} /${obj.fieldID}`,
        y: percentTotalInvoiceVolume
      });
    });

    return results;
  };

  public loadRecogBySupplierForFieldAsync = async (
    startDate: string,
    stopDate: string,
    fieldName: string
  ): Promise<CategoryChartData[]> => {
    const fullData: HistoricalRecogData = await this.getRecognitionApiDataAsync(
      {
        chartType: "recognitionBySupplier",
        startDate: startDate,
        stopDate: stopDate,
        filterType: "fieldName",
        filterVal: fieldName,
        includeValidationServices: true
      }
    );

    const data = fullData["rows"];

    const results: CategoryChartData[] = data.map(
      (obj: HistoricalRecogDataRow) => {
        return {
          x: `${obj.fieldText} /${obj.fieldID}`,
          y:
            obj.fieldCount > 0
              ? Math.floor((obj.recognitionCount / obj.fieldCount) * 100)
              : 0
        };
      }
    );

    return results;
  };

  // PUBLIC METHODS TO LOAD INVOICE VOLUME DATA FROM API

  public loadInvoiceVolumeHistoricalAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<TimelineChartData[]> => {
    const fullData: HistoricalRecogData = await this.getInvoiceApiDataAsync({
      chartType: "historicalInvoiceVolume",
      startDate: startDate,
      stopDate: stopDate
    });

    const data = fullData["rows"];

    // store number of invoices received
    if (data.length > 0) {
      store.commit(
        "InvoiceVolumeAnalyticsStore/setNumberOfInvoicesReceived",
        parseFloat(
          data
            .reduce(
              (accum: number, current: HistoricalRecogDataRow) =>
                accum + current.fieldCount,
              0
            )
            .toFixed()
        ).toLocaleString()
      );
    } else {
      // store default value for no data
      store.commit(
        "InvoiceVolumeAnalyticsStore/setNumberOfInvoicesReceived",
        "0"
      );
    }

    const results: CategoryChartData[] = data.map(
      (obj: HistoricalRecogDataRow) => {
        return {
          x: obj.fieldText,
          y: obj.fieldCount
        };
      }
    );

    return results;
  };

  public loadInvoicesBySupplierAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<CategoryChartData[]> => {
    const fullData: HistoricalRecogData = await this.getInvoiceApiDataAsync({
      chartType: "invoiceVolumeBySupplier",
      startDate: startDate,
      stopDate: stopDate
    });

    const data = fullData["rows"];
    store.commit(
      "InvoiceVolumeAnalyticsStore/setNumberOfSuppliers",
      fullData.rowCount
    );

    const results = data.map((obj: HistoricalRecogDataRow) => {
      return {
        x: `${obj.fieldText} /${obj.fieldID}`,
        y: obj.fieldCount ? obj.fieldCount : 0
      };
    });

    return results;
  };

  public loadInvoicesByProcessorAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<CategoryChartData[]> => {
    const fullData: HistoricalRecogData = await this.getInvoiceApiDataAsync({
      chartType: "invoicesEnteredByProcessor",
      startDate: startDate,
      stopDate: stopDate
    });

    const data = fullData["rows"];

    // store number of AP processors
    store.commit(
      "AutomationAnalyticsStore/setNumberOfApProcessors",
      fullData.rowCount
    );

    const results = data.map((obj: HistoricalRecogDataRow) => {
      return {
        x: obj.fieldText,
        y: obj.fieldCount
      };
    });

    return results;
  };

  public loadInvoicesByAssignmentAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<CategoryChartData[]> => {
    const fullData: HistoricalRecogData = await this.getInvoiceApiDataAsync({
      chartType: "invoiceVolumeByAssignment",
      startDate: startDate,
      stopDate: stopDate
    });

    const data = fullData["rows"];

    store.commit(
      "InvoiceVolumeAnalyticsStore/setNumberOfInvoiceCategories",
      fullData.rowCount
    );

    const results = data.map((obj: HistoricalRecogDataRow) => {
      return {
        x: obj.fieldText,
        y: obj.fieldCount
      };
    });

    return results;
  };

  public loadInvoicesAutoAddedAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<{ [key: string]: CategoryChartData[] }> => {
    const fullData: InvoiceAutoAddData = await this.getInvoiceApiDataAsync({
      chartType: "invoiceVolumeAutoAdded",
      startDate: startDate,
      stopDate: stopDate
    });

    const data = fullData["rows"];

    // store customer's auto-add setting
    if (data.length > 0) {
      if (
        fullData["setting"].itemValue === '"true"' ||
        fullData["setting"].itemValue === "true"
      ) {
        store.commit("AutomationAnalyticsStore/setAutoAddSetting", "On");
      } else if (
        fullData["setting"].itemValue === '"false"' ||
        fullData["setting"].itemValue === "false"
      ) {
        store.commit("AutomationAnalyticsStore/setAutoAddSetting", "Off");
      } else {
        store.commit(
          "AutomationAnalyticsStore/setAutoAddSetting",
          "Conditional"
        );
      }
    } else {
      store.commit("AutomationAnalyticsStore/setAutoAddSetting", "N/A");
    }

    // store total auto-added invoices and added (entered) invoices
    if (data.length > 0) {
      const totalAutoAddedInvoices = data.reduce(
        (accum: number, current: any) => accum + current.recognitionCount,
        0
      );
      const totalAddedInvoices = data.reduce(
        (accum: number, current: any) => accum + current.fieldCount,
        0
      );
      const percentAutoAdded =
        totalAddedInvoices == 0
          ? "0"
          : ((totalAutoAddedInvoices / totalAddedInvoices) * 100).toFixed();

      store.commit(
        "AutomationAnalyticsStore/setAutoAddDataSnapshot",
        `${parseFloat(totalAutoAddedInvoices.toFixed()).toLocaleString()}
          of ${parseFloat(
            totalAddedInvoices.toFixed()
          ).toLocaleString()} (${percentAutoAdded}%)`
      );
    } else {
      store.commit(
        "AutomationAnalyticsStore/setAutoAddDataSnapshot",
        "0 of 0 (0%)"
      );
    }

    // format results for chart
    const results: { [key: string]: CategoryChartData[] } = {
      invoicesAutoAdded: [], // invoices auto-added without customer touch
      invoicesAdded: [] // all entered invoices
    };

    data.forEach((obj: InvoiceAutoAddDataRow) => {
      results.invoicesAutoAdded.push({
        x: obj.fieldText,
        y:
          obj.fieldCount > 0
            ? parseFloat(
                ((obj.recognitionCount / obj.fieldCount) * 100).toFixed(2)
              )
            : 0
      });
      results.invoicesAdded.push({
        x: obj.fieldText,
        y: obj.fieldCount
      });
    });

    return results;
  };

  public loadInvoicesCancelledBySupplierAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<CategoryChartData[]> => {
    const fullData: HistoricalRecogData = await this.getInvoiceApiDataAsync({
      chartType: "invoiceCancelledBySupplier",
      startDate: startDate,
      stopDate: stopDate
    });

    const data = fullData["rows"];
    // store cancelled invoices by supplier
    store.commit(
      "InvoiceVolumeAnalyticsStore/setInvoiceCancelledBySupplierData",
      data.length
    );
    // store number of invoices cancelled
    if (data.length > 0) {
      store.commit(
        "InvoiceVolumeAnalyticsStore/setNumberOfInvoicesCancelled",
        data.reduce(
          (accum: number, current: any) => accum + current.fieldCount,
          0
        )
      );
    } else {
      store.commit(
        "InvoiceVolumeAnalyticsStore/setNumberOfInvoicesCancelled",
        0
      );
    }

    // return results
    return data.map((obj: HistoricalRecogDataRow) => {
      return {
        x: `${obj.fieldText} /${obj.fieldID}`,
        y: obj.fieldCount ? obj.fieldCount : 0
      };
    });
  };

  public loadTouchlessProcessingSnapshotAsync = async (
    startDate: string,
    stopDate: string
  ): Promise<void> => {
    const data: InvoiceAutoAddDataRow[] = await this.getInvoiceApiDataAsync({
      chartType: "touchlessProcessing",
      startDate: startDate,
      stopDate: stopDate
    });

    // store total auto-added invoices and added (entered) invoices
    if (data?.length > 0) {
      const totalTouchlesslyProcessedInvoices = data.reduce(
        (accum: number, current: any) => accum + current.recognitionCount,
        0
      );
      const totalEligibleInvoices = data.reduce(
        (accum: number, current: any) => accum + current.fieldCount,
        0
      );
      const percentTouchlesslyProcessed =
        totalEligibleInvoices == 0
          ? "0"
          : (
              (totalTouchlesslyProcessedInvoices / totalEligibleInvoices) *
              100
            ).toFixed();

      store.commit(
        "AutomationAnalyticsStore/setTouchlessProcessingDataSnapshot",
        `${parseFloat(
          totalTouchlesslyProcessedInvoices.toFixed()
        ).toLocaleString()}
          of ${parseFloat(
            totalEligibleInvoices.toFixed()
          ).toLocaleString()} (${percentTouchlesslyProcessed}%)`
      );
    } else {
      store.commit(
        "AutomationAnalyticsStore/setTouchlessProcessingDataSnapshot",
        "0 of 0 (0%)"
      );
    }
  };

  // PUBLIC METHODS TO LOAD INVOICE LIST DATA FROM API

  public loadInvoiceListAsync = async (
    startDate: string,
    stopDate: string,
    dateType: string,
    supplierId?: string
  ): Promise<InvoiceListData[]> => {
    let fullData;
    if (supplierId) {
      fullData = await this.getInvoiceListApiDataAsync({
        startDate: startDate,
        stopDate: stopDate,
        dateType: dateType,
        supplierId: supplierId
      });
    } else {
      fullData = await this.getInvoiceListApiDataAsync({
        startDate: startDate,
        stopDate: stopDate,
        dateType: dateType
      });
    }
    return fullData.data.map((invoice: InvoiceListData) => {
      invoice.invEnteredDate = invoice.invEnteredDate
        ? utils.utcToLocalDateString(
            moment(invoice.invEnteredDate).toDate(),
            "L"
          )
        : "";
      invoice.invScannedDate = invoice.invScannedDate
        ? utils.utcToLocalDateString(
            moment(invoice.invScannedDate).toDate(),
            "L"
          )
        : "";
      this.setLocalDatesOnFieldsData(invoice.fields);
      return invoice;
    });
  };

  private setLocalDatesOnFieldsData(fieldsData: InvoiceListFieldsData[]) {
    const items = fieldsData.filter(item => item.fldFieldName == "InvoiceDate");
    items.forEach(field => {
      field.fldPreprocessingValue = field.fldPreprocessingValue
        ? utils.utcToLocalDateString(
            moment(field.fldPreprocessingValue).toDate(),
            "L"
          )
        : "";
      field.fldExportedValue = field.fldExportedValue
        ? utils.utcToLocalDateString(
            moment(field.fldExportedValue).toDate(),
            "L"
          )
        : "";
      field.fldErpValue = field.fldErpValue
        ? utils.utcToLocalDateString(moment(field.fldErpValue).toDate(), "L")
        : "";
    });
  }
}

export const reportingAnalyticsService = new ReportingAnalyticsService(); // singleton
