





































import { Vue, Component, Prop } from "vue-property-decorator";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import moment from "moment";

@Component({
  components: {
    "primary-button": PrimaryButton
  }
})
export default class DateRangeSelector extends Vue {
  @Prop() storedStartDate!: string;
  @Prop() storedEndDate!: string;

  private todayDate = moment()
    .format()
    .toString()
    .substring(0, 10);

  private dateRange = {
    startDate: this.todayDate,
    endDate: this.todayDate
  };

  private get areValidDates(): boolean {
    const startDate = moment(this.dateRange.startDate);
    const endDate = moment(this.dateRange.endDate);
    const areValidDates =
      startDate.isValid() && endDate.isValid() && endDate.diff(startDate) >= 0;
    return areValidDates;
  }

  created() {
    // assign values of props to class properties to avoid mutating props
    this.dateRange.startDate = this.storedStartDate;
    this.dateRange.endDate = this.storedEndDate;
  }

  handleDateFormSubmission(event: any) {
    // emit new date range values to parent component
    this.$emit("dateRangeChanged", this.dateRange);
  }
}
